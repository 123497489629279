.button {
  padding: 0.75em 1em;
  text-decoration: none;
  display: flex;
  align-items: center;
  font: inherit;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.buttonTheme1 {
  color: var(--theme1-color);
  background-color: #eeeeee;
  border: 1px var(--theme1-color) solid;
  border-radius: 0.3em;
}


.header {
  font-size: 1.4rem;
  --header-height: 4em;;
  height: var(--header-height);
  background-color: var(--theme1-color);
  display: flex;
}

.link {
  color: white;
  composes: button from "../../styles/global.css";
  text-align: center;
  padding: 0 0.75em;
}

.content {
}

.logo {
  height: var(--header-height);
  width: var(--header-height);
  padding: 0.5em;
}
